import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const GetEvents = () => {
  const { allSanityEvent } = useStaticQuery(graphql`
    query {
      allSanityEvent(sort: { fields: _createdAt }) {
        nodes {
          meta {
            ...MetaCardFields
          }
          settings {
            revereseText
            reverseHeader
            searchExcerpt
            bgColor {
              hex
            }
          }
          slug {
            current
          }
          video
          listingImage {
            image {
              ...ImageWithPreview
            }
          }
          additionalInfo {
            heading
            text
          }
          description: _rawEventDescription(
            resolveReferences: { maxDepth: 100 }
          )
          featuredArtists {
            name
            position {
              name
            }
            bioImage {
              image {
                ...ImageWithPreview
              }
            }
          }
          series
          session
          ticketsLink
          venueLink
          title
          isOneOff
          buttonText
          where {
            id
            location
            title
          }
          repertoire {
            heading
            text
          }
        }
      }
    }
  `);
  return allSanityEvent.nodes || [];
};
